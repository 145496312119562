import { Box, Flex, Text, Link, List, ListItem } from "@chakra-ui/react";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      as="footer"
      className="main-footer"
      pt="60px"
      pb="60px"
      margin="0 auto"
      width="75%"
    >
      <Box as="section" className="vc_row">
        <Box className="container">
          <Flex
            className="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-around"
          >
            <Flex
              className="lqd-column"
              width={{ base: "100%", md: "50%" }}
              alignItems="center"
              textAlign={{ base: "center", md: "left" }}
            >
              <Text fontSize="16px" my="0">
                © Copyright 2024, Toate drepturile rezervate.
              </Text>
            </Flex>
            <Flex
              className="lqd-column"
              width={{ base: "100%", md: "50%" }}
              justifyContent={{ base: "center", md: "flex-end" }}
              alignItems="center"
              mt={{ base: 4, md: 0 }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <List display="flex" className="social-icon social-icon-md">
                <ListItem>
                  <Link
                    href="https://www.facebook.com/officialbookingtime/"
                    target="_blank"
                  >
                    <FaFacebook size="24px" />
                  </Link>
                </ListItem>
              </List>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
