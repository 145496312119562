import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";

export default function Login() {
  return (
    <Container maxW="md" centerContent>
      <Box bg="gray.50" p={8} rounded="lg" boxShadow="lg">
        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          <a href="https://travel-time.ro" title="Travel Time">
            Bookingtime
          </a>
        </Heading>

        <form
          action="https://tbs.flytime.ro/reseller/auth/"
          method="post"
          id="login_form"
        >
          <VStack spacing={4}>
            <FormControl id="reseller_code" isRequired>
              <FormLabel>SALES ID</FormLabel>
              <Input
                type="text"
                name="resellerCode"
                size="md"
                placeholder="Enter your agency code"
                bg="white"
                focusBorderColor="blue.500"
              />
            </FormControl>

            <FormControl id="username" isRequired>
              <FormLabel>User</FormLabel>
              <Input
                type="text"
                name="username"
                size="md"
                placeholder="Enter your username"
                bg="white"
                focusBorderColor="blue.500"
              />
            </FormControl>

            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                size="md"
                placeholder="Enter your password"
                bg="white"
                focusBorderColor="blue.500"
              />
            </FormControl>

            <Input type="hidden" name="action" value="login" />

            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              width="full"
              mt={4}
            >
              Login
            </Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
}
