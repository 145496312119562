import {
  Box,
  Flex,
  Heading,
  Text,
  Link,
  Divider,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";

const ContactUs = () => {
  const headingColor = useColorModeValue("black.500", "black.200");
  const textColor = useColorModeValue("red.700", "red.300");
  const linkColor = useColorModeValue("blue.600", "blue.300");
  const dividerColor = useColorModeValue("black.300", "black.200");
  return (
    <Container maxW="container.xl" py={10}>
      <Flex
        direction={{ base: "column", md: "row" }}
        wrap="wrap"
        align="flex-start"
      >
        <Box
          flexBasis={{ base: "100%", md: "50%" }}
          p={{ base: 4, md: 6 }}
          bgImage="url('assets/img/bg/bg-77.svg')"
          bgRepeat="no-repeat"
          bgPosition="center"
          mb={{ base: 6, md: 0 }}
          data-custom-animations="true"
          data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":30, "opacity":0}, "animations":{"translateY":0, "opacity":1}}'
        >
          <Box mb={6} pr={{ md: 4 }}>
            <Heading
              as="h6"
              textTransform="uppercase"
              color={headingColor}
              mb={2}
            >
              Contact
            </Heading>
            <Heading as="h2" size="lg" color={headingColor}>
              <strong>Contactează-ne</strong> pentru mai multe informații
            </Heading>
          </Box>
        </Box>
        <Box
          //   flexBasis={{ base: "100%", md: "50%" }}
          p={{ base: 4, md: 6 }}
          ml={{ md: 36 }}
        >
          <Heading as="h2" size="2xl" mb={4} mt={0} color={headingColor}>
            Sales Manager
          </Heading>
          <Heading as="h6" size="lg" mb={4} mt={0} color={textColor}>
            Daniel Diaconu
          </Heading>
          <Divider borderColor={dividerColor} width="70%" mb={4} />
          <Text fontSize="18px" color={textColor}>
            +40 724 281 881 <br />
            <Link href="mailto:daniel.diaconu@travel-time.ro" color={linkColor}>
              daniel.diaconu@travel-time.ro
            </Link>
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default ContactUs;
