import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-scroll"; // Import Link from react-scroll
import { Link as RouterLink } from "react-router-dom";
// import TawkConnection from "../../tawk/tawk";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuDisplay = useBreakpointValue({ base: "none", md: "flex" });

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      p={6}
      bg="white"
      color="black"
      position="sticky"
      top={0}
      zIndex={1000}
      boxShadow="md"
      // opacity={0.8}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"tighter"}>
          <img
            src="https://www.bookingtime.eu/assets/img/logo/logo.svg"
            alt=""
          />
        </Heading>
      </Flex>

      {/* Mobile Menu */}
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        icon={<HamburgerIcon />}
        variant="ghost"
        aria-label="Open Mobile Menu"
      />

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4}>
                <Link to="home" smooth={true} duration={500}>
                  <Text cursor="pointer" onClick={onClose}>
                    ACASĂ
                  </Text>
                </Link>
                <Link to="objectives" smooth={true} duration={500}>
                  <Text cursor="pointer" onClick={onClose}>
                    OBIECTIVE
                  </Text>
                </Link>
                <Link to="services" smooth={true} duration={500}>
                  <Text cursor="pointer" onClick={onClose}>
                    SERVICII
                  </Text>
                </Link>
                <Link to="ticketing" smooth={true} duration={500}>
                  <Text cursor="pointer" onClick={onClose}>
                    PREZENTARE
                  </Text>
                </Link>
                <RouterLink to={"/anunturi"} smooth={true} duration={500}>
                  <Text cursor="pointer" onClick={onClose}>
                    ANUNȚURI
                  </Text>
                </RouterLink>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Desktop Menu */}
      <Box display={menuDisplay}>
        <Flex align="center" justify="flex-start" flexGrow={1} mr={2}>
          <Link to="home" smooth={true} duration={500}>
            <Text
              color="black"
              mr={4}
              px={2}
              _hover={{
                color: "red",
              }}
              cursor="pointer"
            >
              ACASĂ
            </Text>
          </Link>
          <Link to="objectives" smooth={true} duration={500}>
            <Text
              color="black"
              mr={4}
              px={2}
              _hover={{
                color: "red",
              }}
              cursor="pointer"
            >
              OBIECTIVE
            </Text>
          </Link>
          <Link to="services" smooth={true} duration={500}>
            <Text
              color="black"
              mr={4}
              px={2}
              _hover={{
                color: "red",
              }}
              cursor="pointer"
            >
              SERVICII
            </Text>
          </Link>
          <Link to="ticketing" smooth={true} duration={500}>
            <Text
              color="black"
              mr={4}
              px={2}
              _hover={{
                color: "red",
              }}
              cursor="pointer"
            >
              PREZENTARE
            </Text>
          </Link>
          <RouterLink to={"/anunturi"} smooth={true} duration={500}>
            <Text
              color="black"
              mr={4}
              px={2}
              _hover={{
                color: "red",
              }}
              cursor="pointer"
            >
              ANUNȚURI
            </Text>
          </RouterLink>
        </Flex>
      </Box>

      {/* Buttons */}
      <Box>
        <Link to="formContact" smooth={true} duration={500}>
          <Button
            fontSize="20px"
            colorScheme="red"
            variant="outline"
            target="_blank"
            mr={4}
            color="white"
            bg="red.400"
            borderRadius="25px"
            _hover={{
              bg: "red.500",
              color: "white",
              boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
            }}
          >
            Contactează-ne
          </Button>
        </Link>
        <ChakraLink href="https://tbs.flytime.ro/reseller/auth/" isExternal>
          <Button
            fontSize="20px"
            colorScheme="red"
            variant="outline"
            target="_blank"
            mr={4}
            color="red"
            bg="white.400"
            borderRadius="25px"
            _hover={{
              boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
            }}
          >
            Login
          </Button>
        </ChakraLink>
        {/* <TawkConnection /> */}
      </Box>
    </Flex>
  );
};

export default Navbar;
