import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./pages/home/home";
import Anunturi from "./pages/anunturi/anunturi";
import Login from "./components/login/Login";

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.React_App_Tracking_ID);

function App() {
  ReactGA.send(window.location.pathname);
  return (
    <ChakraProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/anunturi" element={<Anunturi />}></Route>
          </Routes>
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App;
