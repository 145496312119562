import React from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import {
  FormControl,
  Divider,
  Textarea,
  FormHelperText,
  Input,
  Button,
  Heading,
  Box,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReactGA from "react-ga4";
import { Element } from "react-scroll"; // Import Element from react-scroll

const FormContact = () => {
  const form = useRef();
  const [isPending, setIsPending] = useState(false);
  const toast = useToast();
  const showToast = () => {
    toast({
      position: "top",
      title: "Mesaj Trimis",
      description: "Mesaj trimis cu succes",
      duration: 5000,
      isClosable: true,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    setIsPending(true);

    emailjs
      .sendForm(
        "service_0741uwq",
        "template_j28f7tt",
        form.current,
        "L3--JZGpnxBn-oSQ8"
      )
      .then((result) => {
        console.log(result.text);
        setIsPending(false);

        // Adăugați codul de urmărire a conversiei aici
        ReactGA.send({
          hitType: "event",
          eventCategory: "Formular de contact",
          eventAction: "Trimitere mesaj",
          eventLabel: "Conversie",
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Clear the form fields
        form.current.reset();

        showToast();
      });
  };

  const headingSize = useBreakpointValue({ base: "md", md: "2xl" });
  const marginBottom = useBreakpointValue({ base: "6px", md: "10px" });
  const widthBox = useBreakpointValue({ base: "75%", md: "50%" });

  return (
    <>
      <Element name="formContact" style={{ marginTop: "10px" }}>
        <Box id="formContact" mx="auto" width={widthBox} mt="10px">
          <Heading
            align="center"
            color="red.400"
            as="h1"
            size={headingSize}
            mb={marginBottom}
          >
            Contactează-ne!
          </Heading>

          <form ref={form} onSubmit={sendEmail}>
            <FormControl mb="10px">
              <Input
                type="text"
                name="user_name"
                placeholder="Nume Agenție / Nume Administrator"
                required
              />
            </FormControl>
            <FormControl mb="10px">
              <Input
                type="email"
                name="user_email"
                placeholder="example@gmail.com"
                required
              />
            </FormControl>
            <FormControl mb="10px">
              <Input
                type="number"
                name="mobile_number"
                placeholder="Numar Telefon"
                required
              />
            </FormControl>

            <FormControl>
              <FormHelperText mb="5px">
                Solicitarea dumneavoastră{" "}
              </FormHelperText>
              <Textarea
                mb="10px"
                name="message"
                placeholder="Mesaj"
                rows="4"
                required
              />
            </FormControl>

            {!isPending && (
              <Button colorScheme="red" align="center" type="submit">
                Trimite Mesaj
              </Button>
            )}
            {isPending && (
              <Button align="center" type="submit">
                Se trimite...
              </Button>
            )}
          </form>
        </Box>
      </Element>

      <Divider mt="30px" />
    </>
  );
};

export default FormContact;
