const airlineLogos = [
  {
    id: 1,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Aeroitalia-Logo.jpg",
  },
  {
    id: 2,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Aegean-Airlines-Logo.jpg",
  },
  {
    id: 3,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Aerolineas-Argentinas-Logo.jpg",
  },
  {
    id: 4,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Aeromexico-Logo.jpg",
  },
  {
    id: 5,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Air-Arabia-Logo.jpg",
  },
  {
    id: 6,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/AirAsia-Logo.jpg",
  },
  {
    id: 7,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Astana-Logo.jpg",
  },
  {
    id: 8,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Airbaltic-Logo.jpg",
  },
  {
    id: 9,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/AirCanada-Logo.jpg",
  },
  {
    id: 10,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/AirChina-Logo.jpg",
  },
  {
    id: 11,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Air-Dolomiti-Logo.jpg",
  },
  {
    id: 12,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Europa-Logo.jpg",
  },
  {
    id: 13,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-France-Logo.jpg",
  },
  {
    id: 14,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Air-India-Logo.jpg",
  },
  {
    id: 15,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Macau-Logo.jpg",
  },
  {
    id: 16,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Malta-Logo.jpg",
  },
  {
    id: 17,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Air-Mauritius-Logo.jpg",
  },
  {
    id: 18,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Serbia-Logo.jpg",
  },
  {
    id: 19,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Seychelles-Logo.jpg",
  },
  {
    id: 20,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Air-Transat-Logo.jpg",
  },
  {
    id: 21,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Alaska-Airlines-Logo.jpg",
  },
  {
    id: 22,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/All-Nippon-Airways-Logo.jpg",
  },
  {
    id: 23,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/American-Airliners-Logo.jpg",
  },
  {
    id: 24,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Arkia-Logo.jpg",
  },
  {
    id: 25,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Asiana-Airlines-Logo.jpg",
  },
  {
    id: 26,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Austrian-Airlines-Logo.jpg",
  },
  {
    id: 27,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Avianca-Logo.jpg",
  },
  {
    id: 28,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Azerbaijan-Airlines-Logo.jpg",
  },
  {
    id: 29,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Azul-Brazilian-Airlines-Logo.jpg",
  },
  {
    id: 30,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Bamboo-Airways-Logo.jpg",
  },
  {
    id: 31,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Bangkok-Airways-Logo.jpg",
  },
  {
    id: 32,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/British-Airways-Logo.jpg",
  },
  {
    id: 33,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/AirCanada-Logo.jpg",
  },
  {
    id: 34,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Bulgaria-Air-Logo.jpg",
  },
  {
    id: 35,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Cambodia Air Logo.jpg",
  },
  {
    id: 36,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Cathay-Pacific-Logo.jpg",
  },
  {
    id: 37,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Cebgo-Logo.jpg",
  },
  {
    id: 38,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Cebu-Pacific-Logo.jpg",
  },
  {
    id: 39,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/China-Airlines-Logo.jpg",
  },
  {
    id: 40,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/China-Eastern-Airlines-Logo.jpg",
  },
  {
    id: 41,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/China-Southern-Logo.jpg",
  },
  {
    id: 42,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Condor-Logo.jpg",
  },
  {
    id: 43,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Copa-Airlines-Logo.jpg",
  },
  {
    id: 44,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Croatia-Airlines-Logo.jpg",
  },
  {
    id: 45,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Czech-Airlines-Logo.jpg",
  },
  {
    id: 46,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Delta-Air-Lines-Logo.jpg",
  },
  {
    id: 47,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/EasyJet-Logo.jpg",
  },
  {
    id: 48,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/EgyptAir-Logo.jpg",
  },
  {
    id: 49,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/El-Al-Logo.jpg",
  },
  {
    id: 50,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Emirates-Logo.jpg",
  },
  {
    id: 51,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Ethiopian-Logo.jpg",
  },
  {
    id: 52,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Etihad-Airways-Logo.jpg",
  },
  {
    id: 53,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Eurowings-Logo.jpg",
  },
  {
    id: 54,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/EVA-Air-Logo.jpg",
  },
  {
    id: 55,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/fastjet-Logo.jpg",
  },
  {
    id: 56,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Fiji-Airways-Logo.jpg",
  },
  {
    id: 57,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Finnair-Logo.png",
  },
  {
    id: 58,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Flair-Airlines-Logo.jpg",
  },
  {
    id: 59,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/FlyDubai-Logo.jpg",
  },
  {
    id: 60,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/flyone-Logo.jpg",
  },
  {
    id: 61,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/FLYPLAY-Logo.jpg",
  },
  {
    id: 62,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Frontier-Airlines-Logo.jpg",
  },
  {
    id: 63,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Garuda-Indonesia-Logo.jpg",
  },
  {
    id: 64,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Germanwings-Logo.jpg",
  },
  {
    id: 65,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Gol-Linhas-Aereas-Inteligentes-Logo.jpg",
  },
  {
    id: 66,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Gulf-Air-Logo.jpg",
  },
  {
    id: 67,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Hahn-Air-Logo.jpg",
  },
  {
    id: 68,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Hainan-Airlines-Logo.jpg",
  },
  {
    id: 69,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Hawaiian-Airlines-Logo.jpg",
  },
  {
    id: 70,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/All-Nippon-Airways-Logo.jpg",
  },
  {
    id: 71,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/hisky-Logo.png",
  },
  {
    id: 72,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/HK-Express-Logo.jpg",
  },
  {
    id: 73,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Iberia-logo.jpg",
  },
  {
    id: 74,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Icelandair-Logo.jpg",
  },
  {
    id: 75,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/IndiGo-Logo.jpg",
  },
  {
    id: 76,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/ITA-Airways-Logo.jpg",
  },
  {
    id: 77,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Japan-Airlines-logo.jpg",
  },
  {
    id: 78,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Jeju-Air-Logo.jpg",
  },
  {
    id: 79,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/JetBlue-Airways-Logo.jpg",
  },
  {
    id: 80,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Jetstar-Logo.jpg",
  },
  {
    id: 81,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Kenya-Airways-logo.jpg",
  },
  {
    id: 82,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/KLM-Logo.jpg",
  },
  {
    id: 83,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Korean-Air-Logo.jpg",
  },
  {
    id: 84,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/LATAM-Logo.jpg",
  },
  {
    id: 85,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/LOT-Polish-Airlines-Logo.jpg",
  },
  {
    id: 86,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Lufthansa-Logo.jpg",
  },
  {
    id: 87,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Malaysia-Airlines-Logo.jpg",
  },
  {
    id: 88,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Middle-East-Airlines-Logo.jpg",
  },
  {
    id: 89,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Norwegian-Air-Shuttle- Logo.jpg",
  },
  {
    id: 90,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Peach-Aviation-Logo.jpg",
  },
  {
    id: 91,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Pegasus-Airlines-Logo.jpg",
  },
  {
    id: 92,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Philippine-Airlines-logo.jpg",
  },
  {
    id: 93,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Porter-Airlines-Logo.jpg",
  },
  {
    id: 94,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Precision-Air-Logo.jpg",
  },
  {
    id: 95,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Qantas-logo.jpg",
  },
  {
    id: 96,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Qatar-Airways-logo.jpg",
  },
  {
    id: 97,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Royal-Air-Maroc-logo.jpg",
  },
  {
    id: 98,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Royal-Brunei-Airlines-logo.jpg",
  },
  {
    id: 99,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Aerolineas-Argentinas-Logo.jpg",
  },
  {
    id: 100,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Royal-Jordanian-logo.jpg",
  },
  {
    id: 101,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Saudi-Arabian-Airlines-logo.jpg",
  },
  {
    id: 102,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Scandinavian-Airlines-System-Logo.jpg",
  },
  {
    id: 103,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Air-Astana-Logo.jpg",
  },
  {
    id: 104,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Scoot-Logo.jpg",
  },
  {
    id: 105,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Shenzhen-Airlines-Logo.jpg",
  },
  {
    id: 106,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Singapore-Airlines-Logo.jpg",
  },
  {
    id: 107,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Sky-Airlines-Logo.jpg",
  },
  {
    id: 108,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/SKY-express-Logo.jpg",
  },
  {
    id: 109,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/South-African-Airways-Logo.jpg",
  },
  {
    id: 110,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Spirit-Airlines-Logo.jpg",
  },
  {
    id: 111,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Spring-Airlines-Logo.jpg",
  },
  {
    id: 112,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Srilankan-Airlines-Logo.jpg",
  },
  {
    id: 113,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/SunExpress-Logo.jpg",
  },
  {
    id: 114,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Swiss-International-Air-Lines-Logo.jpg",
  },
  {
    id: 115,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/TAP-Portugal-Logo.jpg",
  },
  {
    id: 116,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Thai-Airways-Logo.jpg",
  },
  {
    id: 117,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Tigerair-Logo.jpg",
  },
  {
    id: 118,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Transavia-Logo.jpg",
  },
  {
    id: 119,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/TransNusa-Logo.jpg",
  },
  {
    id: 120,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/TUI-fly-Logo.jpg",
  },
  {
    id: 121,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Tunisair-Logo.jpg",
  },
  {
    id: 122,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Turkish-Airlines-logo.jpg",
  },
  {
    id: 123,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/United-Logo.jpg",
  },
  {
    id: 124,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Uzbekistan-Airways-Logo.jpg",
  },
  {
    id: 125,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/VietJet-Air-Logo.jpg",
  },
  {
    id: 126,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Vietnam-Airlines-logo.jpg",
  },
  {
    id: 127,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Virgin-Atlantic-Logo.jpg",
  },
  {
    id: 128,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Virgin-Australia-Logo.jpg",
  },
  {
    id: 129,
    name: "Airline 2",
    imageUrl: "/assets/logoairlines/Volaris-Logo.jpg",
  },
  {
    id: 130,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Vueling-Airlines-Logo.jpg",
  },
  {
    id: 131,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/WestJet-Airlines-Logo.jpg",
  },
  {
    id: 132,
    name: "Airline 1",
    imageUrl: "/assets/logoairlines/Xiamen-Airlines-Logo.jpg",
  },
];

export default airlineLogos;
