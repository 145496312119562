import React from "react";
import {
  Box,
  Container,
  Heading,
  Center,
  Text,
  Flex,
  Image,
  Stack,
  Icon,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-scroll"; // Import Link from react-scroll
import { MdLocalHotel } from "react-icons/md";
import { Element } from "react-scroll"; // Import Element from react-scroll

const categories = [
  {
    icon: MdLocalHotel, // Replace with actual icon
    title: "Expedia",
    text: "este lider global în furnizarea de soluții de cazari hoteliere, oferind acces la o gamă extinsă de hoteluri și alte opțiuni. Prin integrarea acestui frunizor de top suntem în măsură să oferim un inventar vast de optiuni de cazare în întreaga lumea, de la pensiuni și apartamente, până la hoteluri de lux, la cele mai convenabile tarife.",
    logos: ["/images/furnizori/Expedia-Logo.jpg"],
  },
  {
    icon: MdLocalHotel,
    title: "HotelBeds",
    text: "este un furnizor de cazări hoteliere de renume, cu o mare tradiție și de excelentă calitate în zona serviciilor B2B. Prin acest frunizor punem la dispoziție partenerilor noștri peste 170.000 de unități de cazare din întreaga lume, la cele mai competitive prețuri.",
    logos: ["/images/furnizori/Hotelbeds_Logo_full.png"],
  },
  {
    icon: MdLocalHotel,
    title: "Hotelcon",
    text: "este un furnizor de cazări forte bine cunoscut pe piața locală, de încredere, care s-a impus prin calitatea serviciului și diversitatea ofertelor naționale și worldwide, la tarife foarte bune.",
    logos: ["/images/furnizori/Hotelcon NewER Logo.png"],
  },
  {
    icon: MdLocalHotel,
    text: "O serie întreagă de alți furnizori de tradiție sunt implementați în platforma noastră, pentru a asigura o dinamică tarifară bună și o gama variată de hoteluri sau unități de cazari, care să acopera toate nevoile partenerilor noștri. Amintim aici: Restel, Stuba, TBO, Travco, W2m.",
    logos: [
      "/images/furnizori/Restel Logo.jpg",
      "/images/furnizori/Stuba-Logo.jpg",
      "/images/furnizori/TBO LOGO.svg",
      "/images/furnizori/LOGO-W2M.webp",
      "/images/furnizori/travco logo.png",
      "/images/furnizori/ratehawk.jpg",
    ],
  },
];

const CategoryBox = ({ icon, text, title, logos }) => {
  // const displayBox = useBreakpointValue({ base: "block", md: "flex" });
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const textAlign = useBreakpointValue({ base: "center" });
  return (
    <Box
      p={4}
      alignItems="center"
      borderWidth="1px"
      borderRadius="lg"
      width="100%"
      mb={4}
      _hover={{ shadow: "md" }}
    >
      <Flex direction={flexDirection} alignItems="center">
        <Center>
          <Icon as={icon} boxSize={6} mr={4} aria-label={title} />
        </Center>
        <Flex
          direction="column"
          align={textAlign === "center" ? "center" : "flex-start"}
          flex="1"
        >
          <Center fontSize={20} fontWeight={600} mb={2}>
            {title}
          </Center>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {logos.map((logo, index) => (
              <Image key={index} src={logo} alt="Logo" maxW="150px" m={2} />
            ))}
          </Box>
          <Text textAlign={textAlign}>{text}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const Hotels = () => (
  <Element name="hotels" style={{ marginTop: "30px" }}>
    <Box
      // backgroundImage="url('https://cdn.prod.website-files.com/5a9ee6416e90d20001b20038/635ad09ca86f57b85be10967_horizontal%20-%202022-10-27T214026.282.svg')"
      backgroundSize="cover"
      backgroundPosition="center"
      py={10}
      mb={30}
    >
      <Container maxW="container.md">
        <Heading
          as="h2"
          size="xl"
          mb={6}
          ml={5}
          textAlign="center"
          color="black"
        >
          Servicii Hoteliere
        </Heading>
        <Stack spacing={4}>
          {categories.map((category, index) => (
            <CategoryBox
              key={index}
              icon={category.icon}
              title={category.title}
              text={category.text}
              logos={category.logos}
            />
          ))}
        </Stack>
        <Center
          justify="center"
          align="center"
          as="h1"
          fontSize={{ base: "2xl", md: "3xl" }}
          mt="15px"
        >
          <Link to="formContact" smooth={true} duration={500}>
            <Button
              fontSize="20px"
              target="_blank"
              colorScheme="red"
              variant="outline"
              mr={4}
              color="red"
              bg="white.400"
              borderRadius="25px"
              _hover={{
                boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
              }}
            >
              Solicită un cont demo!
            </Button>
          </Link>
        </Center>
      </Container>
    </Box>
  </Element>
);

export default Hotels;
