import { Box } from "@chakra-ui/react";
import {
  Image,
  Button,
  Heading,
  Center,
  Text,
  Link,
  Stack,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaWhatsapp, FaGift, FaBell, FaCogs } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

const Anunturi = () => {
  const headerResponsiveKLwidth = useBreakpointValue({
    base: "100%",
    md: "75%",
  });

  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });

  const presentationResponsiveKLheight = useBreakpointValue({
    base: "250px",
    lg: "400px",
  });

  const fontSizeTextFilter = useBreakpointValue({ base: "lg", lg: "xl" });

  const widthResponsive = useBreakpointValue({ base: "100%", md: "75%" });
  return (
    <Box
      borderColor="gray.100"
      borderWidth="2px"
      mt="10px"
      width={widthResponsive}
      mx="auto"
    >
      <Box
        className="heading"
        width="100%"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        <Heading
          width={widthResponsiveHeading}
          mx="auto"
          mt="7px"
          textAlign="center"
          size="lg"
          as="h1"
          mb={4}
        >
          Rămâi conectat cu noutățile din platforma BOOKINGTIME!
          <Image
            src="https://www.bookingtime.eu/assets/img/logo/logo.svg"
            alt="images"
            w="300px"
            mx="auto"
            mt={5}
            objectFit="cover"
          />
        </Heading>

        {/* <Center
          className="centerText"
          borderBottom="1px solid"
          fontSize={textResponsive}
          fontWeight="bold"
          color="#00d4ff"
          px="6"
          mb="6"
        >
          Premium Comfort
        </Center> */}
        <Image
          src="/images/anunturi-header.jpg"
          alt="images"
          w={headerResponsiveKLwidth}
          mx="auto"
          h={presentationResponsiveKLheight}
          objectFit="cover"
        />

        <Text
          fontSize={fontSizeTextFilter}
          mb={6}
          w={headerResponsiveKLwidth}
          mx="auto"
        >
          Suntem încântați să te invităm să te alături grupului nostru WhatsApp
          unde vei primi, în timp real, cele mai noi informații despre platforma
          noastră B2B dedicată agențiilor de turism. Aici vei găsi:
        </Text>
        <Center>
          <Stack spacing={3}>
            {/* Oferte exclusive */}
            <Box display="flex" alignItems="center">
              <Icon as={FaGift} boxSize={5} color="green.500" mr={3} />
              <Text fontSize={fontSizeTextFilter} fontWeight="medium">
                Oferte exclusive
              </Text>
            </Box>

            {/* Noutăți și actualizări despre platformă */}
            <Box display="flex" alignItems="center">
              <Icon as={FaBell} boxSize={5} color="green.500" mr={3} />
              <Text fontSize={fontSizeTextFilter} fontWeight="medium">
                Noutăți și actualizări despre platformă
              </Text>
            </Box>

            {/* Soluții personalizate pentru afacerea ta */}
            <Box display="flex" alignItems="center">
              <Icon as={FaCogs} boxSize={5} color="green.500" mr={3} />
              <Text fontSize={fontSizeTextFilter} fontWeight="medium">
                Soluții personalizate pentru afacerea ta
              </Text>
            </Box>
          </Stack>
        </Center>
        <Center marginTop={10}>
          <Link
            href="https://chat.whatsapp.com/H3kT47s4gFTLu6ZJyxoZK3"
            isExternal
            _hover={{ textDecoration: "none" }}
            display="inline-flex"
            alignItems="center"
            fontSize={fontSizeTextFilter}
            color="green.500"
            fontWeight="bold"
          >
            <Icon as={FaWhatsapp} boxSize={6} mr={2} />
            Alătură-te grupului
            <Text as="span" ml={2} fontSize="xl">
              ...
            </Text>
          </Link>
        </Center>
      </Box>
      <Center marginTop={10}>
        <RouterLink to={"/"} smooth={true} duration={500}>
          <Button
            fontSize="20px"
            target="_blank"
            colorScheme="teal"
            variant="outline"
            mr={4}
            color="green.500"
            bg="white.400"
            borderRadius="25px"
            _hover={{
              boxShadow: "4px 4px rgba(0, 128, 128,0.9)",
            }}
          >
            Întoarce-te la pagina principală
          </Button>
        </RouterLink>
      </Center>
    </Box>
  );
};

export default Anunturi;
