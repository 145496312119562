import React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  Center,
  Image,
  Divider,
  Button,
  ChakraProvider,
} from "@chakra-ui/react";
import { Link } from "react-scroll"; // Import Link from react-scroll
import "./reservation2.css";
import { Element } from "react-scroll"; // Import Element from react-scroll

const ReservationsSection = () => {
  const images = [
    {
      src: "https://images.unsplash.com/photo-1556388158-158ea5ccacbd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de transport aerian",
      title: "Servicii de transport aerian",
      alt: "Servicii de transport aerian",
      linkTo: "ticketing",
    },
    {
      src: "https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      text: "Servicii de cazare",
      title: "Servicii de cazare",
      alt: "Servicii de cazare",
      linkTo: "hotels",
    },
  ];
  const gridResponsive = useBreakpointValue({ base: "block", lg: "grid" });
  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "45px",
  });
  const descriptionResponsive = useBreakpointValue({
    base: "13px",
    md: "20px",
  });

  return (
    <Element name="services" style={{ marginTop: "30px" }}>
      <Container maxW="container.xl" py={{ base: 10, md: 15 }}>
        <Flex direction="column">
          <Box mb={10} textAlign="center">
            <Heading as="h6" size="md" textTransform="uppercase">
              Rezervări
            </Heading>
            <Heading as="h2" fontWeight="bold">
              Sistem de rezervări
            </Heading>
            <Text
              fontSize="lg"
              lineHeight="1.5"
              mt={4}
              mb={6}
              maxW="540px"
              mx="auto"
            >
              Sistemul nostru de rezervări reprezinta un sistem avansat de
              ghiseu de rezervări care integrează la nivel înalt cei mai mari
              furnizori de pe piața călătoriilor, oferind servicii precum:
            </Text>
          </Box>
          <ChakraProvider>
            <Box
              display={gridResponsive}
              gridTemplateColumns="repeat(2, 1fr)"
              gap={2}
            >
              {images.map((image, index) => (
                <Box key={index} className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <Image
                        src={image.src}
                        alt={image.alt}
                        w="100%"
                        h="100%"
                        objectFit="cover"
                      />
                      <Center
                        className="flip-card-text"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        color="white"
                        textAlign="center"
                        fontSize={textResponsive}
                      >
                        {image.text}
                      </Center>
                    </div>
                    <div className="flip-card-back">
                      <Heading
                        width="100%"
                        mx="auto"
                        background="teal"
                        color="white"
                        mt="7px"
                        textAlign="center"
                        size="lg"
                        as="h1"
                        mb={4}
                        fontSize={descriptionResponsive}
                      >
                        {image.title}
                      </Heading>
                      <Box className="back-content">
                        <Link to={image.linkTo} smooth={true} duration={500}>
                          <Button
                            fontSize={descriptionResponsive}
                            colorScheme="teal"
                          >
                            Mai multe detalii
                          </Button>
                        </Link>
                      </Box>
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </ChakraProvider>
        </Flex>
        <Divider mt="5px" />
      </Container>
    </Element>
  );
};

export default ReservationsSection;
